@import "./global.scss";
@import "./Components/datatable.module.scss";

body {
    -webkit-font-smoothing: antialiased !important;
    -webkit-appearance: none !important;
    overflow-x: hidden !important;

    &::-webkit-scrollbar {
        display: none !important;
        width: 0 !important;
    }

    // &::-webkit-scrollbar {
    //     width: 0.125rem;
    // }

    // &::-webkit-scrollbar-thumb {
    //     background-color: $argent !important;
    //     border-radius: 0.25rem;
    // }
}

::-webkit-scrollbar {
    display: none !important;
}

// .css-vu613u-MenuList {
//     &::-webkit-scrollbar {
//         display: block !important;
//         width: 0.25rem !important;
//     }
//     &::-webkit-scrollbar-thumb {
//         background-color: $primary-color !important;
//         border-radius: 0.25rem;
//     }
// }

.css-wiz2j1-menu {
    div[role=listbox] {
        overflow-x: hidden;

        &::-webkit-scrollbar {
            display: block !important;
            width: 0.25rem !important;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }
}

a {
    text-decoration: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

input:focus-visible {
    outline: none !important;
}

.ps-4.pe-lg-5.pe-md-2.pe-2.padding-42px-top,
.ps-4.pe-lg-5.pe-md-2.pe-2.padding-32px-top {
    // padding-top: 6.25rem;
    // margin-left: 1.75rem;
    // margin-right: 3.75rem;
    height: max-content;
    overflow-y: scroll;
    // overflow-x: hidden;
}

// header
.e-heading-icons {
    background-color: rgb(224, 224, 224, 0.15);

    .e-badge {
        top: -0.5rem;
        right: -0.375rem;
        width: 1.125rem;
        height: 1.125rem;
        border-radius: 6px;
    }
}

.e-header {
    // width: 100%;
    z-index: 9;

    .e-filter {
        width: 47.375rem;
        top: 3rem;
        box-shadow: -0.25rem 0.3125rem 1rem 0 rgba(0, 0, 0, 0.1);
    }
}

.e-toggle-icon {
    position: absolute;
    top: 6rem;
    right: -0.75rem;
    cursor: pointer;
    z-index: 1036;
}

.e-toggle-icon-responsive {
    position: absolute;
    top: 6rem;
    right: -0.75rem;
    cursor: pointer;
    z-index: 1036;
}

// date filter
.e-filter-date-picker {
    top: 3.125rem;
    right: 0;
    z-index: 2;
    box-shadow: 0 1rem 2rem 0 rgba(23, 37, 76, 0.12);

    .rdrDefinedRangesWrapper {
        padding: 1rem 1.875rem 1rem 0.75rem;
        /* 16px = 1rem */
        font-size: 0.875rem;
        /* 14px / 16 = 0.875rem */
        width: 9.8125rem;
        /* 157px / 16 = 9.8125rem */
        border-right: 0.0625rem solid $bright-gray;
        /* 1px / 16 = 0.0625rem */
        border-top-left-radius: 0.5rem;
        /* 8px / 16 = 0.5rem */
        border-bottom-left-radius: 0.5rem;
        /* 8px / 16 = 0.5rem */

        .rdrStaticRange {
            border-bottom: none;
            color: $outer-space;
            font-family: "Poppins-regular";

            .rdrStaticRangeLabel {
                line-height: 1.25rem;
                /* 20px / 16 = 1.25rem */
                padding: 0.5rem 0 0.5rem 0.5rem;
                /* 8px / 16 = 0.5rem */
            }

            &:hover {
                .rdrStaticRangeLabel {
                    background-color: $light-sky-blue;
                }
            }
        }

        .rdrStaticRangeSelected {
            color: $primary-color !important;
            background-color: $bubbles;
            font-weight: 400;
        }
    }

    .rdrDateRangeWrapper {
        border-top-right-radius: 0.5rem;
        /* 8px / 16 = 0.5rem */
        border-bottom-right-radius: 0.5rem;
        /* 8px / 16 = 0.5rem */
        position: relative;

        .rdrMonths {
            margin-top: 1.875rem;

            .rdrMonth {
                padding-left: 1rem;
                padding-right: 1rem;
                width: 16.75rem;

                .rdrMonthName {
                    color: $black;
                    font-family: "Poppins-SemiBold";
                    font-size: 0.875rem;
                    padding: 0rem 0.4em;
                    text-align: center;
                    margin-bottom: 0.5rem;
                }

                .rdrWeekDays {
                    .rdrWeekDay {
                        color: $black;
                        font-family: "Inter-regular";
                        font-size: 0.875rem;
                    }
                }

                .rdrDays {
                    .rdrDay {
                        color: $black !important;
                        font-family: "Poppins-regular";
                        font-size: 0.75rem;
                        /* 12px / 16 = 0.75rem */
                        line-height: 0.75rem;
                        /* 12px / 16 = 0.75rem */

                        .rdrStartEdge,
                        .rdrEndEdge {
                            background-color: $vivid-cerulean;
                            border-radius: 0;
                        }

                        .rdrInRange {
                            background-color: $diamond;
                            border-radius: 0;
                        }

                        .rdrDayStartPreview,
                        .rdrDayEndPreview {
                            background-color: transparent;
                        }

                        .rdrDayStartPreview,
                        .rdrDayEndPreview,
                        .rdrDayInPreview {
                            border: none;
                        }
                    }

                    .rdrDayToday {
                        border: 0.0625rem solid $gray;

                        .rdrDayNumber span {
                            color: $gray;
                        }
                    }

                    .rdrDayToday:has(.rdrEndEdge),
                    .rdrDayToday:has(.rdrStartEdge) {
                        border: none;
                        color: $white;
                        background-color: $vivid-cerulean;
                    }

                    .rdrDayToday .rdrDayNumber span:after {
                        background: transparent;
                    }

                    .rdrDay:has(.rdrStartEdge),
                    .rdrDay:has(.rdrEndEdge) {
                        background-color: $vivid-cerulean;
                    }

                    .rdrDay:has(.rdrInRange) {
                        background-color: $diamond;
                    }

                    .rdrDayPassive {
                        pointer-events: unset;
                        // display: none;
                    }

                    .rdrDayPassive span {
                        color: $black;
                    }

                    .rdrDayPassive:has(.rdrEndEdge) {
                        .rdrDayNumber span {
                            color: $white;
                        }
                    }

                    // .rdrDayPassive {
                    //     .rdrDayNumber span {
                    //         color: $white;
                    //     }
                    // }

                    .rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span // .rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span

                        {
                        color: $black;
                    }

                    .rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
                    .rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span {
                        color: $white;
                    }
                }
            }
        }

        .e-custom-date-navigator {
            top: 1.875rem;

            .e-left-icon {
                position: absolute;
                left: 2.75rem;
                background-color: transparent;
                border: none;
                padding: 0;
                top: 0;
            }

            .e-right-icon {
                position: absolute;
                right: 3.375rem;
                background-color: transparent;
                border: none;
                padding: 0;
                top: 0;
            }
        }
    }
}

.e-cover-image {
    transition: 0.3s all ease-in-out;
    cursor: pointer;

    &:hover {
        transition: 0.3s all ease-in-out;
        transform: scale(1.03);
    }
}

.e-body-content {
    min-height: 100vh;
    padding-top: 6rem;
    // overflow-y: scroll;
}

.e-side-bar {
    width: inherit;

    .e-admin-type {
        border-radius: 10px 0px 0px 10px;
    }
}

.e-footer {
    margin-top: 80px;
}

.e-ring-loader {
    // transform: translateY(-14%);

    display: block;
    // width: 25px;
    // height: 25px;
    // margin: 8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: ring 1.2s linear infinite;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// slider
.e-horizontal-slider {
    width: 100%;
    height: 6px;
    border-radius: 16px;
}

.e-track {
    position: relative;
    background: #1177bd;
    height: 6px;
    border-radius: 16px;
}

.e-track.e-track-1 {
    background: #e5e5e5;
}

.e-horizontal-slider .e-track {
    height: 6px;
}

.e-horizontal-slider .e-thumb {
    text-align: center;
    background-image: url("../Assets/Images/Quiz//thumb.svg");
    top: -10px;
    height: 24px;
    width: 24px;
}

// portfolio page
.e-portfolio {
    .e-icon {
        top: 8px;
        right: 8px;
    }

    .e-returns {
        top: -8px;
        right: 0px;
    }

    .e-bg-image {
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
    }

    .e-width-333 {
        width: 20.813rem;
    }

    .e-fund-order-hover {
        max-width: 0;
        overflow: hidden;
        transition: max-width 0.3s ease-out;
    }

    .e-fund-order-hover.show {
        max-width: 1.5rem;
        /* 24px / 16 = 1.5rem */
        /* Set the desired width */
    }

    .e-external-fund {
        width: 13.25rem;
        right: -0.625rem;
        top: -3rem;
        box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.06);
    }

    .e-portfolio-options {
        top: 1.875rem;
        right: 0;
        width: 12.5rem;
        box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.25);
    }

    .e-portfolio-funds {
        >div:first-of-type {
            overflow: visible;
        }
    }
}

.e-progress-status {
    width: 20.063rem;
    height: 1.5rem;

    .e-invested-hover,
    .e-return-hover {
        border-radius: 0.375rem;
        background: $white;
        box-shadow: 0.25rem 0.25rem 0.75rem 0 rgba(175, 175, 175, 0.58);
        padding: 0;
        opacity: 1;
        z-index: 1;
    }
}

.e-fund-order-summary {
    .e-fund-chip {
        padding: 0.25rem 0.375rem;
        /* 4px / 16 = 0.25rem, 6px / 16 = 0.375rem */
    }

    .e-line-progress-status {
        width: max-content;

        .e-progress-line {
            height: 0.0625rem;
            width: 82%;
            top: 0.75rem;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .e-border-bottom {
        position: absolute;
        height: 0.0625rem;
        width: 76%;
    }
}

.e-view-details {
    left: -3rem;
    top: -1.875rem;
    z-index: 99;
    background: white;
    border-radius: 6px;
}

/* wysiwig editor */
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    padding-bottom: 300px;

    border-radius: 0px 0px 80px 8px;
    background-color: $white;
    margin-top: 20px;

    // background-color: $white;
    p {
        color: $black !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 25px;
        margin-bottom: 0;
    }

    p:nth-of-type(1) {
        margin-bottom: 20px;
    }

    h6 {
        font-weight: 600 !important;
        font-size: 14px !important;
    }
}

.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    border: none !important;
    box-shadow: none !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border: none;
    padding: 12px 12px 12px 0;
    background-color: $white !important;
    border-bottom: 1.6px solid #bdbdbd !important;
}

.ck.ck-dropdown {
    &[aria-labelledby="ck-editor__aria-label_e74d295f4749ee2c9e4e0304f597225aa"] {
        display: none !important;
    }
}

.ck .ck-button {
    &[aria-disabled="true"] {
        display: none !important;
    }
}

.ck-toolbar__items {
    .ck-dropdown:nth-of-type(1) {
        display: none !important;
    }

    .ck-dropdown:nth-of-type(3) {
        display: none !important;
    }

    .ck-dropdown:nth-of-type(2) {
        display: none !important;
    }
}

.ck-sticky-panel__content {
    z-index: 2 !important;
}

.ck-toolbar {
    &[aria-label="Dropdown toolbar"] {
        border-radius: 0px !important;
    }
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
    display: none !important;
}

.ck-file-dialog-button {
    // display: none!important;
}

.ck .ck-button[aria-labelledby="ck-editor__aria-label_eca6d855d9e456b2b02e398f738ab9740"] {
    // display: none!important;
}

.ck .ck-button[aria-labelledby="ck-editor__aria-label_e26a6ab70fcce2fc537813a2e58106fc7"] {
    // display: none!important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: none !important;
}

.ck.ck-editor__main>.ck-editor__editable {
    padding: 0 100px 0 28px !important;
    border-bottom: 1.6px solid #bdbdbd !important;
    height: 680px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        // border-radius: 10px;
        height: 10px !important;
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #dcdbdb;
        height: 10px !important;
        // border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        // height: 10px !important;
    }
}

.ck.ck-editor__editable_inline {
    border: none !important;
}

.ck .ck-button[aria-labelledby="ck-editor__aria-label_edd9fb7b17c77d13ecab5376a0a4cda51"] {
    display: none !important;
}

.ck.ck-toolbar>.ck-toolbar__items> :not(.ck-toolbar__line-break) {
    margin-right: 30px !important;
}

.e-manage-info .ck.ck-editor__main>.ck-editor__editable {
    height: 10rem !important;
    border: none !important;
}

.e-parse-content p {
    margin-bottom: 0px !important;
    color: $outer-space !important;
}

.e-show-scrollbar {
    padding-right: 10px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        // border-radius: 10px;
        height: 0px !important;
        width: 6px;
        display: block !important;
    }

    &::-webkit-scrollbar-thumb {
        background: $alice-blue;
        height: 10px !important;
        border-radius: 10px;
    }
}

// quiz
.e-risk-radio-span {
    span {
        font-family: "Poppins-Regular";
        font-size: 1rem;
    }
}

.e-risk-range {
    max-width: 29.375rem;

    .e-range-slider {
        width: 70%;
        margin-top: 0.563rem;

        .e-labels {
            margin-top: 0.75rem;
        }
    }
}

.e-financial {
    width: max-content;

    .e-financial-risk {
        width: 4rem;
        height: 4rem;
    }
}

.e-others {
    .e-fund-order-hover {
        max-width: 0;
        overflow: hidden;
        transition: max-width 0.3s ease-out;
    }

    .e-fund-order-hover.show {
        max-width: 1.5rem;
        /* 24px / 16 = 1.5rem */
        /* Set the desired width */
    }
}

.e-quick-invest {
    .e-trending {
        top: 1.5rem;
        left: 0;
    }

    .e-quick-invest-card {
        cursor: pointer;

        &:hover {
            border-radius: 1.5rem;
            // border: 0.0625rem solid $anti-flash-white;
            background: $white;
            box-shadow: 0 0.75rem 0.75rem 0 rgba(50, 50, 71, 0.08);
        }
    }
}

.e-arrow-right-180 {
    transform: rotate(180deg);
}

.e-select-label {
    transform: translateY(0%);
    transition: ease 0.4s;
    z-index: 3;
}

.e-select-label-placeholder {
    transform: translateY(54%);
    transition: ease 0.4s;
    position: absolute;
    left: 0.75rem;
    // top: 1rem;
    cursor: pointer;
    z-index: 0;
}

.e-select-label-active {
    // padding: 0.375rem;
    z-index: 3;
    transform: translateY(-50%);
    transition: ease 0.4s;
    color: #222b38;
    left: 0.75rem;
    // top: 0;
    z-index: 3;
}

// login
.e-login-bg {
    background-image: url("../Assets/Images/Login/spiral.png");
    background-size: cover;
    background-repeat: no-repeat;
}

// fund portfolio detail page
.e-fund-portfolio-detail {
    .e-fund-order-hover {
        max-width: 0;
        overflow: hidden;
        transition: max-width 0.3s ease-out;
    }

    .e-fund-order-hover.show {
        max-width: 1.5rem;
        /* 24px / 16 = 1.5rem */
        /* Set the desired width */
    }

    .e-portfolio-options {
        top: 2.5rem;
        right: 0;
        width: 12.5rem;
        box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.25);
    }
}

.e-filter-popup-height {
    max-height: 400px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 0.25rem;
        height: 0;
        display: block !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $alice-blue !important;
        border-radius: 0.25rem;
    }
}

// tooltip
.e-tool-tip-container {
    cursor: pointer;

    &:hover {
        &::after {
            display: block;
        }
    }

    &::after {
        content: attr(data-tooltip-content);
        position: absolute;
        top: -100%;
        left: 50%;
        transform: translate(-50%, -40%);
        // top: -36px;
        // left: -100%;
        padding: 6px;
        box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.25);
        color: $black;
        font-size: 12px;
        font-weight: 400;
        background-color: $white;
        display: none;
        width: max-content;
    }
}

#pagination-first-page,
#pagination-last-page {
    display: none;
}

#pagination-next-page,
#pagination-last-page {
    opacity: 0;
    pointer-events: none;

    &:focus {
        background-color: transparent !important;
    }

    &:hover {
        background-color: transparent !important;
    }
}

// SIP date picker
.e-sip-date-picker-panel {
    width: 23rem;
    /* 375px / 16 = 23.4375rem */
    height: 20.75rem;
    /* 300px / 16 = 18.75rem */
    padding: 1.25rem 0.75rem;
    left: 43.5%;

    /* 20px / 16 = 1.25rem, 12px / 16 = 0.75rem */
    .e-sip-date-picker-date {
        transition: ease 0.3s;

        &:hover {
            font-weight: 700;
            background-color: $light-blue-shade;
        }
    }
}

.e-disabled-date {
    background-color: $american-silver !important;
    color: $black !important;
}

.no-events {
    pointer-events: none;
}

.rdt_Pagination {

    select,
    svg {
        display: none !important;
    }
}

.rdt_Pagination span:nth-child(3) {
    display: none !important;
}

.e-app-zoom-level-9 {
    zoom: 0.9 !important;

    .e-sidebar {
        position: fixed !important;
        height: 100%;
        width: 15% !important;
    }

    .e-header {
        width: 89vw;
    }
}

.e-app-zoom-level-8 {
    zoom: 0.8 !important;

    .e-sidebar {
        position: fixed !important;
        height: 100%;
        width: 15% !important;
    }

    .e-header {
        width: 100vw;
    }
}

.e-app-zoom-level-7 {
    zoom: 0.6 !important;

    .e-sidebar {
        position: fixed !important;
        height: 100%;
        width: 15% !important;
    }

    .e-header {
        width: 80%;
    }

    @media (-webkit-device-pixel-ratio: 1.5) {
        .e-header {
            width: 100vw !important;
        }
    }

}

.e-admin-list .rdt_Pagination {
    border-top: 0;
}

.e-name-link {
    &:hover {
        &::after {
            width: 100%;
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.25rem;
        width: 0;
        height: 0.09375rem;
        transition: all 0.5s ease;
        border-radius: 0.25rem;
        background-color: $primary-color;
    }
}

.e-modal-close-btn {
    border-radius: 100px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }
}

.e-filter-badge {
    top: -0.375rem;
    right: -0.375rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 7px;
}

.modal-backdrop {
    width: 100%;
    height: 100%;
}

.modal-backdrop.show {
    width: 100%;
    height: 100%;
}


.edit-link-share {
    transition: box-shadow .3s;
    border-radius: 8px;
    border: 1px solid #ecedef;

    &:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, .2);
    }
}

.e-search-suggestion-dropdown {
    &::-webkit-scrollbar {
        display: block !important;
        width: 0.25rem !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary-color !important;
        border-radius: 0.25rem;
    }
}

.e-copy-link-hover {
    border-color: $bright-gray !important;

    &:hover {
        border-color: $primary-color !important;
    }
}

.e-file-viewer {
    iframe {
        &::-webkit-scrollbar{
            display: none;
          }
    }
      
}

.e-mandate-status {
    text-transform: lowercase;

    &::first-letter {
        text-transform: uppercase;
    }
}

.Toastify__toast-body > div:last-child {
    padding-right: 10px!important;
}

.e-custom-select-box {
    .e-label {
        bottom: 0;
        left: 1rem;
    }

    .e-transition-center {
        top: 0;
    }

    .e-transition-top {
        top: -0.7rem;
        left: 15px;
        padding-left: 0.375rem;
        padding-right: 0.375rem;
        margin: 0;
        transition: top 0.5s ease;
    }

    .e-error {
        bottom: -1.3rem;
    }
}

.e-date-label {
    padding: 1.02rem 3.125rem 1rem 0.9375rem;
    box-shadow: 0px 0.375rem 0.25rem 0px rgba(50, 50, 71, 0.03);

    .e-error {
        bottom: -1.3rem;
    }
}

.e-date-of-birth {
    top: 54px;

    .react-calendar {
        border: none;
        border-radius: 8px;
        width: 100%;

        .react-calendar__navigation__label {
            color: $black;
            font-family: "Poppins-medium";
            font-size: 1.125rem;
        }

        .react-calendar__month-view__weekdays__weekday {
            color: $black;
            font-family: "Poppins-regular";
            text-decoration: none;
            @extend %font16;
            text-transform: capitalize;
            font-weight: 500;
            font-size: 0.875rem;

            abbr[title] {
                cursor: pointer !important;
                text-decoration: none !important;
            }
        }

        .react-calendar__tile--now {
            background-color: $white;
        }

        .react-calendar__tile {
            font-size: 0.875rem;

            &:hover {
                background-color: $baby-blue-eye;
                font-weight: 600;
            }
        }

        .react-calendar__navigation {
            button {
                &:hover {
                    background-color: $baby-blue-eye;
                }
            }
        }

        .react-calendar__navigation__label {
            pointer-events: unset;
        }

        .react-calendar__tile:disabled,
        .react-calendar__navigation button:disabled {
            pointer-events: none;
            background: transparent;
            color: $american-silver !important;
        }

        .react-calendar__tile--active {
            background-color: $vivid-cerulean;
            color: $white;
        }
    }
}

.e-order-milestone-wrapper {
    &::-webkit-scrollbar {
        height: 0.175rem;
        display: block!important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.25rem;
        background-color: $primary-color;
    }
}

.e-transform-returns {
    transform: rotate(180deg);
}

// Create account
.e-kyc-user-type-card {
    padding: 20px;
}
.e-kyc-user-type-card {
    height: 197px;
    &[data-coming-soon="true"] {
        &::after {
            content: "";
            position: absolute;
            top: -5px;
            right: 29%;
            background-color: $primary-color;
            width: 10px;
            height: 10px;
            z-index: -1;
        }

        &::before {
            content: "";
            position: absolute;
            top: 63px;
            right: -4.5px;
            background-color: $primary-color;
            width: 10px;
            height: 10px;
            z-index: -1;
        }
    }
}

.e-kyc-type-card-ribbon {
    padding: 2px 0;
    width: 115px;
    transform: rotate(45deg);
    top: 18px;
    right: -31px;
}

.e-kyc-investment-type {
    white-space: nowrap;
    font-size: 18px;
}


.e-kyc-progress-track {
    height: 4px;
    width: 88px;

    .e-kyc-progress-bar {
        height: 4px;
        width: 0;
        transition: width ease-in-out 1s;

        &[data-completed-step="true"] {
            width: 100%;
        }

        &[data-completing-step="true"] {
            animation: increase-progress 0.5s forwards;
        }

        &[data-prev-step="true"] {
            animation: decrease-progress 0.5s forwards;
        }
    }
}


@keyframes increase-progress {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@keyframes decrease-progress {
    0% {
        width: 100%;
    }

    100% {
        width: 0;
    }
}

.e-allocation-error {
    top: -1.25rem;
}

.e-fund-detail-graph {
    height: auto;
    .e-chart-container {
        width: 100%;
        canvas {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.e-doughnut-chart-container {
    top: -0.9375rem;
    left: 0;
}

.e-fund-detail {
    .e-units-held {
        white-space: nowrap;
        left: -3.875rem;
        /* -62px / 16 = -3.875rem */
        top: -2.25rem;
        /* -36px / 16 = -2.25rem */
    }

    .e-bookmark-hover {
        white-space: nowrap;
        top: -2.125rem;
        /* -34px / 16 = -2.125rem */
        left: -3rem;
        /* -48px / 16 = -3rem */
    }

    .e-chip {
        width: max-content;
        padding: 0.375rem 0.625rem;
        /* 6px / 16 = 0.375rem, 10px / 16 = 0.625rem */
    }

    .e-icon-rotate {
        transform: rotate(180deg);
    }
}

.e-doughnut-canvas {
    width: 100% !important;
    height: 17.5rem !important;
    /* 280px / 16 = 17.5rem */
}

.e-fund-social-media {
    right: -2rem;
    /* -46px / 16 = -2.875rem */
    bottom: -1rem;
    /* -36px / 16 = -2.25rem */
}

.e-share-icon {
    width: 1.875rem;

    svg {
        fill: $black;
        cursor: pointer;

        &:hover {
            fill: $light-blue-green;
        }
    }
}
.e-share-icon-wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.e-explore-filter-popup {
    top: 3rem;
    /* 48px / 16 = 3rem */
    box-shadow: -0.25rem 0.3125rem 1rem 0 rgba(0, 0, 0, 0.1);
    /* -4px / 16 = -0.25rem, 5px / 16 = 0.3125rem, 16px / 16 = 1rem */
    width: 47.313rem;

    .e-investment-amount {
        div {
            margin-top: 0 !important;

            svg {
                margin-right: 0.5rem !important;
            }

            span {
                font-size: 0.875rem;
                line-height: 1.5rem;
                color: $black;
            }
        }
    }

    .e-close {
        top: 0.5rem;
        right: 1rem;
    }

    .e-top-amc {
        height: 12rem;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .e-theme {
        height: 9.625rem;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .e-filter-by {
        div {
            span {
                font-family: "Poppins-regular";
            }
        }

        height: 9.25rem;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .e-theme,
    .e-top-amc,
    .e-fund-category,
    .e-filter-by {
        &::-webkit-scrollbar {
            width: 0.125rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }

    .e-fund-category {
        height: 10.625rem;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}


.e-investment-search {
    outline: none;
    padding-right: 2.75rem !important;
}

.e-investment-search-button {
    border-radius: 0 8px 8px 0;
}

.e-investment-search-icon {
    right: 0.75rem;
    top: 25%;
}

.react-tooltip {
    z-index: 10 !important;
}

.rdt_TableCol_Sortable:hover span,
.rdt_TableCol_Sortable:hover span.__rdt_custom_sort_icon__ * {
    opacity: 1 !important;
}
.rdt_TableCol_Sortable:hover, .rdt_TableCol_Sortable:hover span, .rdt_TableCol_Sortable:hover span.__rdt_custom_sort_icon__ * {
    opacity: 1 !important;
}


.e-banner-dropdown-icon {
    transition: transform 0.3s ease;
    transform: rotate(90deg);
}

[data-rotate-icon="true"] {
    transition: transform 0.3s ease;
    transform: rotate(-90deg);
}

.e-banner-dropdown {
    top: 2.125rem;
    right: 0;
    padding: 0.375rem 0.625rem;
    box-shadow: 0 0.25rem 1.25rem -0.625rem rgba(0, 0, 0, 0.25);

    p {
        white-space: nowrap;
    }
}

.e-tooltip-general {
    border-radius: 0.375rem !important;
    background: $white !important;
    box-shadow: 0.25rem 0.25rem 0.75rem 0 rgba(175, 175, 175, 0.58);
    padding: 10px !important;
    opacity: 1 !important;
    z-index: 99 !important;
    max-width: 250px;
    text-align: start;
    color: $outer-space !important;
    border: 1px solid $bright-gray !important;
    font-family: 'Poppins-Regular';
    font-size: 12px !important;
    line-height: 16px !important;

    &::after {
        display: none !important;
    }

    p {
        margin-bottom: 0;
    }
}

.e-doughnut-canvas-dashboard {
    width: 100% !important;
    height: 13.5rem !important;
}

.e-doughnut-canvas-dashboard {
    width: 100% !important;
    height: 13.5rem !important;
}

.e-holdings-table {
    .rdt_TableBody {
        margin-top: 0;
    }

    .rdt_TableCol_Sortable>div {
        text-align: start;
    }

    .rdt_TableHeadRow> :last-of-type .rdt_TableCol_Sortable>div {
        text-align: end;
    }

    .rdt_TableCell>div:first-of-type,
    .rdt_TableCell>p:first-of-type {
        text-align: start;
    }
}

.e-preview-edit {
    padding-top: 2px;
    padding-bottom: 2px;
    top: -1.375rem;
    right: -0.5rem;
    box-shadow: 4px 4px 12px 0px rgba(175, 175, 175, 0.58);
}

.e-forgot-pin {
    &:hover {
        &::before {
            width: 100%;
        }
    }

    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0.062rem;
        background-color: $primary-color;
        bottom: -0.125rem;
        left: 0;
        transition: ease width 0.4s;
    }
}

.e-retirement {
    .e-retirement-banner {
        background: linear-gradient(274deg, #effef9 0.82%, #059669 67.69%);

        img {
            border-radius: 0 1.25rem 1.25rem 0;
        }
    }

    .e-steps {
        padding-left: 8.75rem;
        padding-right: 8.75rem;
    }
}

.e-fund-table-wrapper {
    min-height: 120vh;
    .rdt_TableCell {
        &:first-child {
            padding: 22px 0 22px 24px !important;
        }
    }
}

.e-fund-order-hover {
    max-width: 0;
    overflow: hidden;
    transition: max-width 0.3s ease-out;
}

.e-fund-order-hover.show {
    max-width: 1.5rem;
}

.e-goal-banner-image {
    z-index: 0;
    position: absolute;
}

.e-goal-banner {
    padding: 2.75rem 1.5rem 1.875rem 1.5rem;
    /* 44px / 16 = 2.75rem, 24px / 16 = 1.5rem, 30px / 16 = 1.875rem */
}


.e-horizontal-line {
    width: 88%;
    height: 0.0625rem;
    background-color: #d1d5db;
    position: absolute;
    top: 2.5rem;
    z-index: 0;
    left: 54%;
    transform: translateX(-50%);
}

.e-investment-style-popup {
    box-shadow: 0px 0.25rem 0.5rem 0px rgba(16, 24, 64, 0.08);
    width: 23.75rem;
    right: 1rem;
}

.e-comparison-wrapper { 
    display: flex;
    flex-direction: column;
}

.e-compare-table {
    width: 100%;
    border: 1px solid $bright-gray;
        td {
            padding: 1.125rem 0.625rem;
            border: 1px solid $bright-gray;
            width: 20%;
            vertical-align: baseline;
        }
        .e-row-title {
            padding: 1.125rem 1.875rem;
        }
}

.word-break-all {
    word-break: break-all;
}
.e-text-capitalize {
    text-transform: lowercase;
}

.e-text-capitalize::first-letter {
    text-transform: uppercase;
}
