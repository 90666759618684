@import "../global.scss";

.e_download_btn {
    display: inline-flex;
    padding: 8px 36px 8px 8px;
    background-image: url("../../Assets/Images/Users/download.png");
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: 24px;
}
.e_disabled_btn {
    pointer-events: none;
    opacity: 0.7;
}

.e_download_loader {
    display: inline-flex;
    padding: 8px 36px 8px 8px;
    // background-image: url("../../Assets/Images/Users/download.png");
    // background-repeat: no-repeat;
    // background-position: 95% center;
    // background-size: 24px;
}
.e_loader_ring {
    transform: translateY(-14%);
    position: absolute;
    right: -1.5rem;
    &::after {
        content: "";
        display: block;
        // width: 25px;
        // height: 25px;
        // margin: 8px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 3px solid #000;
        border-color: #000 transparent #000 transparent;
        animation: ring 1.2s linear infinite;
    }
}
@keyframes ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
