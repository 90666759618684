@import "../global.scss";

.e_delete {
    .e_dialog {
        max-width: 35.375rem;
    }

    .e_content {
        border-radius: 2rem;
        // height: 95vh;
    }
}

.e_approve_kyc {
    .e_dialog {
        max-width: 40.375rem;
    }

    .e_content {
        border-radius: 2rem;
        // height: 95vh;
    }

    .e_error_class {
        bottom: -18px !important;
    }
}
