
.e_popup_tooltip {
    position: absolute;
    top: calc(100% + -65px);
    right: calc(100% + -55px);
    padding: 2px 12px;
    opacity: 0.9;
    align-items: center;
    animation: tooltipJump 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
    width: max-content;
}

.e_popup_arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #000;
}

@keyframes tooltipJump {
    0% {
        opacity: 0;
        transform: translateY(8px);
    }

    100% {
        opacity: 0.9;
        transform: translateY(0);
    }
}