/*
 *   File : color.scss
 *   Author : https://evoqins.com
 *   Description : SCSS file for define and export colors
 *   Integrations : NA
 *   Version : 1.0.0
*/

// Color declaration
$primary-color: #1177bd;
$granite-gray: #666666;
$black: #222b38;
$old-silver: #828282;
$white: #fff;
$white-smoke: #f5f4f4;
$platinum: #e5e5e5;
$ghost-white: #f8fafc;
$floral-white: #fffbee;
$bright-gray: #ecedef;
$anti-flash-white: #f2f2f2;
$cornsilk: #fff9e1;
$sea-green: #209e49;
$alice-dark-blue: #f4faff;
$chinese-silver: #cccccc;
$dark-liver: #4d4d4d;
$pearl-aqua: #84dcc8;
$jett-black: #000000;
$ufo-green: #27cf5e;
$magnolia: #f5f5ff;
$davys-grey: #555555;
$charcoal: #3f455d;
$cadet-grey: #9299b5;
$lotion: #fffcfc;
$light-lotion: #fafafa;
$lavender-web: #e6e6f5;
$outer-space: #44475b;
$sapphire: #084ec7;
$true-blue: #0572e3;
$gray: #7c7e8c;
$gray-light: #808080;
$eerie-black: #1a1a1a;
$azureish-white: #d5e2f6;
$rich-black: #040505;
$ateneo-blue: #104061;
$new-car: #164dc5;
$red: #ff0000;
$light-silver: #d0d5dd;
$mint-cream: #f7fffd;
$chinese-white: #e0e0e0;
$orange-soda: #eb5b3c;
$pastel-green: #c9f4d7;
$dark-silver: #676d75;
$may-green: #5a9847;
$honey-dew: #ecfaf0;
$pale-steel-blue: #f1f9fe;
$alice-blue: #f1f6ff;
$nyanza: #ddf6e4;
$cream_green: #ddf6e4;
$greenish-yellow: #f6fff2;
$pale-blue: #b5c6f2;
$light-blue: #ebf3ff;
$deep-blue: #2a64d9;
$blue-sapphire: #2a63d6;
$light-gray: #d1d1d6;
$light-black: #222b38;
$dark-black: #010101;
$yellow-green: #bce893;
$charleston-green: #252c32;
$gainsboro: #dfdfdf;
$minty: #d7f7e1;
$cream: #fefbe8;
$icy-blue: #f7fbff;
$light-yellow: #f5ffe5;
$pale-green: #e6f7cb;
$green-crayola: #27ae60;
$pale-white: #f0f0f0;
$mint-green: #f4fef5;
$blue-gray: #d1d5db;
$deep-red: #bc2f32;
$silver: #d9d9d9;
$pale-mint-green: #e3ffe4;
$tea-green: #c1fac3;
$off-white: #f7f8ff;
$minty-cream: #f5ffed;
$baby-power: #fcfff8;
$seaform-green: #baedbd;
$jordy-blue: #95a4fc;
$baby-blue: #eaf6f9;
$light-greenish-white: #f1fff5;
$yellow-orange: #ffad31;
$lust: #e01818;
$vivid-cerulean: #009cf4;
$dark-jungle-green: #101828;
$baby-blue-eye: #a2c7ff;
$space-cadet: #1c274c;
$dark-spring-green: #1a823c;
$bubbles: #e4f4ff;
$american-silver: #d0d0d0;
$light-sky-blue: #f1f9ff;
$very-light-malachite-green: #66e08e;
$milk: #f9fff4;
$azure: #effef9;
$water: #d8fef1;
$dark-water: #ddf0ff;
$jet-stream: #afdccd;
$floral-white: #fffaf2;
$cosmic-latte: #fff8eb;
$columbia-blue: #cbd5e1;
$columbia-blue-dark: #bee6dc;
$diamond: #bbe7ff;
$argent: #c1c1c1;
$quick-silver: #9ea3aa;
$light-grayish-white: #f9f9f9;
$mint-cream: #f5fffd;
$light-pastel-blue-gray: #eefaf7;
$pastel-turquoise: #c2f1e6;
$pastel-sky-blue: #bfe5ff;
$kenyan-copper: #771505;
$medium-carmine: #bc4c2e;
$antique-white: #ffe9d9;
$chinese-orange: #fa703f;
$light-sky: #E5F5FF;
$beau-blue: #b8d5ea;
$light-blue-green: #92e0cd;
$pale-yellow: #fffef7;
$greenish-white: #f3fff7;
$pale-baby-blue: #def2ff;
$azure-blue: #0f91d2;
$pale-jade: #deffdd;
$pale-lime: #bdffbb;
$vivid-lime: #9bff99;
$blonde: #fbf9c1;
$sun-lit-yellow: #f9f6a1;
$sunflower: #f6f171;
$canary-yellow: #f4ee51;
$bisque: #ffdacf;
$peachy-pink: #ffc0ad;
$apricoat: #ffa68b;
$light-malachite-green: #6cd592;
$pale-purple: #fafff7;
$cultured: #f7f7f7;
$snow: #fff7f7;
$pure-white: #fbfbff;
$soft-green: #f8fff4;
$pale-cream: #fffdf8;
$off-white-color: #fffaee;
$lavender: #eaebff;
$pale-lavender: #d9dbff;
$lavender-blue: #c7c9f7;
$polished-pine: #52af9a;
$pale-turquoise: #ecfffb;
$pale-blueish: #ecf2fa;
$pale-buttery-yellow: #fffff5;
$light-beige: #f5f5f3;
$bright-navy-blue: #106ee8;
$green-crayola: #27ae60;
$pastel-blue: #ebf7ff;
$dark-charcoal: #333333;
$caribbean-green: #00a58c;
$mustard: #FCD34D;
$pale-alice-blue: #EEF8FF;
$light-blue-shade: #abc5f1;
$serene-blue: #F6F9FF;
$sky-blue: #C9E7FB;
$sea-blue: #EDF6FF;
$vivid-orange: #FF6B07;
$sunset-blaze: #FF7518;
$red-orange: #FF6551;
$soft-pink: #FEE2E2;
$mint-ice: #D0F6ED;
$azure-mist: #F7F9FF;
$silver-gray: #E1E1E1;
$very-light-alice-blue: #f0f9ff;
$alabaster: #F8F8F8;
$ocean-blue: #0C77BD;

// Color export
:export {
    primary: $primary-color;
    white: $white;
    gray: $gray;
    black: $black;
    pale_blue: $pale-blue;
    deep_blue: $deep-blue;
    blue_sapphire: $blue-sapphire;
    light_black: $light-black;
    charleston_green: $charleston-green;
    gainsboro: $gainsboro;
    outer_space: $outer-space;
    jet_black: $jett-black;
    space_cadet: $space-cadet;
    greenish_yellow: $greenish-yellow;
    anti_flash_white: $anti-flash-white;
    baby_power: $baby-power;
    water: $water;
    jet_stream: $jet-stream;
    bright_gray: $bright-gray;
    milk: $milk;
    pearl_aqua: $pearl-aqua;
    light_gray: $light-gray;
    beau_blue: $beau-blue;
    platinum: $platinum;
    light_blue_green: $light-blue-green;
    columbia_blue: $columbia-blue;
    red: $red;
    argent: $argent;
    eerie_black: $eerie-black;
    baby_blue: $baby-blue;
    pale_buttery_yellow: $pale-buttery-yellow;
    light_beige: $light-beige;
    alice_blue: $alice-blue;
    alice_dark_blue: $alice-dark-blue;
    dark_water: $dark-water;
    bright_navy_blue: $bright-navy-blue;
    dark_black: $dark-black;
    quick_silver: $quick-silver;
    snow: $snow;
    green_crayola: $green-crayola;
    light_lotion: $light-lotion;
    anti_flash_white: $anti-flash-white;
    pale_jade: $pale-jade;
    pale_lime: $pale-lime;
    vivid_lime: $vivid-lime;
    blonde: $blonde;
    sun_lit_yellow: $sun-lit-yellow;
    sunflower: $sunflower;
    canary_yellow: $canary-yellow;
    bisque: $bisque;
    peachy_pink: $peachy-pink;
    apricoat: $apricoat;
    mustard: $mustard;
    old_silver: $old-silver;
}