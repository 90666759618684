
@import "../global.scss";

.e_remove {
    .e_remove_dialog {
        max-width: 490px;

        .e_remove_content {
            border-radius: 2rem;
            border: none;
            padding: 2rem 1.5rem;

        }
    }
}