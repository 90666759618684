/*
 *   File : global.css
 *   Author : https://evoqins.com
 *   Description : CSS file
 *   Integrations : NA
 *   Version : 1.0.0
*/

@charset "UTF-8";
@import "./color.module.scss";

#root {
    overflow-x: hidden !important;
    -ms-overflow-style: none;

    button,
    input {
        outline: none;
    }
}

body {
    font-size: 1rem;
}

.no-scrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
}

.scrollbar-container {
    padding-bottom: 5px;
   &::-webkit-scrollbar {
        width: 0.125rem !important;
        display: block!important;
        height: 0.25rem !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $argent !important;
        border-radius: 0.25rem !important;
    }
}

/*----- Poppins font family ------*/

@font-face {
    font-family: "Poppins-Bold";
    src: url(../Assets/Fonts/Poppins/Poppins-Bold.ttf);
    //  font-weight: 700;
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url(../Assets/Fonts/Poppins/Poppins-SemiBold.ttf);
    //  font-weight: 600;
}

@font-face {
    font-family: "Poppins-Medium";
    src: url(../Assets/Fonts/Poppins/Poppins-Medium.ttf);
    //  font-weight: 500;
}

@font-face {
    font-family: "Poppins-Regular";
    src: url(../Assets/Fonts/Poppins/Poppins-Regular.ttf);
    //  font-weight: 400;
}

@font-face {
    font-family: "Poppins-Light";
    src: url(../Assets/Fonts/Poppins/Poppins-Light.ttf);
    //  font-weight: 300;
}

/*----- Inter font family ------*/
@font-face {
    font-family: "Inter-Extra-Bold";
    src: url(../Assets/Fonts/Inter/Inter-ExtraBold.ttf);
    //  font-weight: 800;
}

@font-face {
    font-family: "Inter-Bold";
    src: url(../Assets/Fonts/Inter/Inter-Bold.ttf);
    //  font-weight: 700;
}

@font-face {
    font-family: "Inter-SemiBold";
    src: url(../Assets/Fonts/Inter/Inter-SemiBold.ttf);
    //  font-weight: 600;
}

@font-face {
    font-family: "Inter-Medium";
    src: url(../Assets/Fonts/Inter/Inter-Medium.ttf);
    //  font-weight: 500;
}

@font-face {
    font-family: "Inter-Regular";
    src: url(../Assets/Fonts/Inter/Inter-Regular.ttf);
    //  font-weight: 400;
}

@font-face {
    font-family: "Inter-Light";
    src: url(../Assets/Fonts/Inter/Inter-Light.ttf);
    //  font-weight: 300;
}

// --- Font family ----

// Poppins
.e-poppins-light {
    font-family: "Poppins-Light";
}

.e-poppins-regular {
    font-family: "Poppins-Regular";
}

.e-poppins-medium {
    font-family: "Poppins-Medium";
}

.e-poppins-semi-bold {
    font-family: "Poppins-SemiBold";
}

.e-poppins-bold {
    font-family: "Poppins-Bold";
}

// Inter
.e-inter-light {
    font-family: "Inter-Light";
}

.e-inter-regular {
    font-family: "Inter-Regular";
}

.e-inter-medium {
    font-family: "Inter-Medium";
}

.e-inter-semi-bold {
    font-family: "Inter-SemiBold";
}

.e-inter-bold {
    font-family: "Inter-Bold";
}

.e-inter-extra-bold {
    font-family: "Inter-Extra-Bold";
}

/* --- Font variables sizes --- */
%font8 {
    font-size: 0.5rem;
}

%font10 {
    font-size: 0.625rem;
}

%font12 {
    font-size: 0.75rem;
}

%font14 {
    font-size: 0.875rem;
}

%font16 {
    font-size: 1rem;
}

%font18 {
    font-size: 1.125rem;
}

%font20 {
    font-size: 1.25rem;
}

%font22 {
    font-size: 1.375rem;
}

%font24 {
    font-size: 1.5rem;
}

%font28 {
    font-size: 1.75rem;
}

%font30 {
    font-size: 1.875rem;
}

%font32 {
    font-size: 2rem;
}

%font36 {
    font-size: 2.25rem;
}

%font40 {
    font-size: 2.5rem;
}

%font52 {
    font-size: 3.25rem;
}

%font56 {
    font-size: 3.5rem;
}

// font sizes
.e-font-8 {
    @extend %font8;
}

.e-font-10 {
    @extend %font10;
}

.e-font-12 {
    @extend %font12;
}

.e-font-14 {
    @extend %font14;
}

.e-font-16 {
    @extend %font16;
}

.e-font-18 {
    @extend %font18;
}

.e-font-20 {
    @extend %font20;
}

.e-font-22 {
    @extend %font22;
}

.e-font-24 {
    @extend %font24;
}

.e-font-28 {
    @extend %font28;
}

.e-font-30 {
    @extend %font30;
}

.e-font-32 {
    @extend %font32;
}

.e-font-36 {
    @extend %font36;
}

.e-font-40 {
    @extend %font40;
}

.e-font-52 {
    @extend %font52;
}

.e-font-56 {
    @extend %font56;
}

// font-colors
.color-primary-color {
    color: $primary-color;
}

.color-granite-gray {
    color: $granite-gray;
}

.color-white {
    color: $white;
}

.color-quick-silver {
    color: $quick-silver;
}

.color-ocean-blue {
    color: $ocean-blue;
}

.color-black {
    color: $black !important;
}

.color-dark-black {
    color: $dark-black;
}

.color-dark-charcoal {
    color: $dark-charcoal;
}

.color-old-silver {
    color: $old-silver;
}

.color-eerie-black {
    color: $eerie-black;
}

.color-sea-green {
    color: $sea-green;
}

.color-dark-liver {
    color: $dark-liver;
}

.color-jett-black {
    color: $jett-black;
}

.color-ufo-green {
    color: $ufo-green;
}

.color-davys-grey {
    color: $davys-grey;
}

.color-charcoal {
    color: $charcoal;
}

.color-cadet-grey {
    color: $cadet-grey;
}

.color-outer-space {
    color: $outer-space;
}

.color-gray {
    color: $gray;
}

.color-gray-light {
    color: $gray-light;
}

.color-eerie-black {
    color: $eerie-black;
}

.color-rick-black {
    color: $rich-black;
}

.color-red {
    color: $red;
}

.color-charleston-green {
    color: $charleston-green;
}

.color-light-silver {
    color: $light-silver;
}

.color-light-black {
    color: $light-black;
}

.color-chinese-white {
    color: $chinese-white;
}

.color-orange-soda {
    color: $orange-soda;
}

.color-dark-silver {
    color: $dark-silver;
}

.color-may-green {
    color: $may-green;
}

.color-green-crayola {
    color: $green-crayola;
}

.color-pale-white {
    color: $pale-white;
}

.color-deep-red {
    color: $deep-red;
}

.color-off-white {
    color: $off-white;
}

.color-yellow-orange {
    color: $yellow-orange;
}

.color-lust {
    color: $lust;
}

.color-dark-jungle-green {
    color: $dark-jungle-green;
}

.color-dark-spring-green {
    color: $dark-spring-green;
}

.color-kenyan-copper {
    color: $kenyan-copper;
}

.color-medium-carmine {
    color: $medium-carmine;
}

.color-azure-blue {
    color: $azure-blue;
}

.color-polished-pine {
    color: $polished-pine;
}

.color-vivid-orange {
    color: $vivid-orange;
}

.color-sunset-blaze {
    color: $sunset-blaze;
}

.color-red-orange {
    color: $red-orange;
}

// background color
.bg-ocean-boat-blue {
    background-color: $primary-color;
}

.bg-columbia-blue-dark {
    background-color: $columbia-blue-dark;
}

.bg-white {
    background-color: $white !important;
}

.bg-ghost-white {
    background-color: $ghost-white;
}

.bg-floral-white {
    background-color: $floral-white;
}

.bg-cornsilk {
    background-color: $cornsilk;
}

.bg-alice-blue {
    background-color: $alice-blue;
}

.bg-snow {
    background-color: $snow;
}

.bg-dark-alice-blue {
    background-color: $alice-dark-blue;
}

.bg-water {
    background-color: $dark-water;
}

.bg-pearl-aqua {
    background-color: $pearl-aqua;
}

.bg-magnolia {
    background-color: $magnolia;
}

.bg-lotion {
    background-color: $lotion;
}

.bg-sapphire {
    background-color: $sapphire;
}

.bg-pale-alice-blue {
    background-color: $pale-alice-blue;
}

.bg-true-blue {
    background-color: $true-blue;
}

.bg-bright-gray {
    background-color: $bright-gray;
}

.bg-mint-cream {
    background-color: $mint-cream;
}

.bg-black {
    background-color: $black !important;
}


.bg-white-smoke {
    background-color: $white-smoke;
}

.bg-chinese-white {
    background: $chinese-white !important;
}

.bg-pastel-green {
    background-color: $pastel-green;
}

.bg-sea-green {
    background-color: $sea-green;
}

.bg-prime-gradient {
    background: linear-gradient(180deg, #0575e6 0%, #093ab8 100%);
}

.bg-bright-navy-blue {
    background-color: $bright-navy-blue;
}

.bg-honey-dew {
    background-color: $honey-dew;
}

.bg-pale-steel-blue {
    background-color: $pale-steel-blue;
}

.bg-alice-blue {
    background-color: $alice-blue;
}

.bg-nyanza {
    background-color: $nyanza;
}

.bg-greenish-yellow {
    background-color: $greenish-yellow;
}

.bg-may-green {
    background-color: $may-green;
}

.bg-light-blue {
    background-color: $light-blue;
}

.bg-light-gray {
    background-color: $light-gray;
}

.bg-light-silver {
    background-color: $light-silver;
}

.bg-minty {
    background-color: $minty;
}

.bg-cream {
    background-color: $cream;
}

.bg-light-yellow {
    background-color: $light-yellow;
}

.bg-pale-green {
    background-color: $pale-green;
}

.bg-pale-white {
    background-color: $pale-white;
}

.bg-mint-green {
    background-color: $mint-green !important;
}

.bg-blue-gray {
    background-color: $blue-gray;
}

.bg-silver {
    background-color: $silver;
}

.bg-pale-mint-green {
    background-color: $pale-mint-green;
}

.bg-tea-green {
    background-color: $tea-green;
}

.bg-off-white {
    background-color: $off-white;
}

.bg-minty-cream {
    background-color: $minty-cream;
}

.bg-baby-powder {
    background-color: $baby-power;
}

.bg-seaform-green {
    background-color: $seaform-green;
}

.bg-jordy-blue {
    background-color: $jordy-blue;
}

.bg-baby-blue {
    background-color: $baby-blue;
}

.bg-light-greenish-white {
    background-color: $light-greenish-white;
}

.bg-jet-black {
    background-color: $jett-black;
}

.bg-vivid-cerulean {
    background-color: $vivid-cerulean;
}

.bg-baby-blue-eye {
    background-color: $baby-blue-eye;
}

.bg-bubbles {
    background-color: $bubbles;
}

.bg-icy-blue {
    background-color: $icy-blue;
}

.bg-platinum {
    background-color: $platinum;
}

.bg-light-sky-blue {
    background-color: $light-sky-blue;
}

.bg-very-light-malachite-green {
    background-color: $very-light-malachite-green;
}

.bg-milk {
    background-color: $milk;
}

.bg-azure {
    background-color: $azure;
}

.bg-baby-power {
    background-color: $baby-power;
}

.bg-floral-white {
    background-color: $floral-white;
}

.bg-cosmic-latte {
    background-color: $cosmic-latte;
}

.bg-light-grayish-white {
    background-color: $light-grayish-white;
}

.bg-light-pastel-blue-gray {
    background-color: $light-pastel-blue-gray;
}

.bg-pastel-turquoise {
    background-color: $pastel-turquoise;
}

.bg-pastel-sky-blue {
    background-color: $pastel-sky-blue;
}

.bg-antique-white {
    background-color: $antique-white;
}

.bg-chinese-orange {
    background-color: $chinese-orange;
}

.bg-pale-yellow {
    background-color: $pale-yellow;
}

.bg-greenish-white {
    background-color: $greenish-white;
}

.bg-pale-baby-blue {
    background-color: $pale-baby-blue;
}

.bg-light-malachite-green {
    background-color: $light-malachite-green;
}

.bg-pale-purple {
    background-color: $pale-purple;
}

.bg-cultured {
    background-color: $cultured;
}

.bg-pure-white {
    background-color: $pure-white;
}

.bg-soft-green {
    background-color: $soft-green;
}

.bg-pale-cream {
    background-color: $pale-cream;
}

.bg-off-white-color {
    background-color: $off-white-color;
}

.bg-lavender {
    background-color: $lavender;
}

.bg-pale-lavender {
    background-color: $pale-lavender;
}

.bg-pale-turquoise {
    background-color: $pale-turquoise;
}

.bg-pale-blueish {
    background-color: $pale-blueish;
}

.bg-pastel-blue {
    background-color: $pastel-blue;
}

.bg-light-lotion {
    background-color: $light-lotion;
}

.bg-serene-blue {
    background-color: $serene-blue;
}

.bg-sky-blue {
    background-color: $sky-blue;
}

.bg-sea-blue {
    background-color: $sea-blue;
}

.bg-soft-pink {
    background-color: $soft-pink;
}

.bg-mint-ice {
    background-color: $mint-ice;
}

.bg-azure-mist {
    background-color: $azure-mist !important;
}

.bg-sliver-gray {
    background-color: $silver-gray;
}

.bg-very-light-alice-blue {
    background-color: $very-light-alice-blue;
}

.bg-alabaster {
    background-color: $alabaster;
}

.bg-light-sky {
    background-color: $light-sky;
}

// height
.h-2px {
    height: 0.125rem;
}

.h-10px {
    height: 0.625rem;
}

.h-16px {
    height: 1rem;
}

.h-40px {
    height: 3rem;
}

.h-60vh {
    height: 60vh;
}

.h-80vh {
    height: 80vh;
}

.h-100vh {
    height: 100vh;
}

.h-100 {
    height: 100% !important;
}

.h-214px {
    height: 13.375rem;
}

.h-150vh {
    height: 150vh;
}

.h-100px {
    height: 100px;
}

.h-64px {
    height: 4rem;
}

.h-90 {
    height: 90%;
}

.h-22px {
    height: 1.375rem;
}

.h-24px {
    height: 1.5rem;
}

.h-39px {
    height: 39px;
}

.h-95 {
    height: 95%;
}

.h-50px {
    height: 3.125rem;
}

.h-52px {
    height: 3.25rem;
}

.h-80vh {
    height: 80vh;
}

.h-60vh {
    height: 60vh;
}

.h-20px {
    height: 1.25rem;
}

.h-72px {
    height: 4.5rem;
}

.h-290px {
    max-height: 18.125rem;
}

.h-450px {
    height: 28.125rem;
}

.h-fit-content {
    height: fit-content;
}

.h-max-content {
    height: max-content !important;
}

.h-initial {
    height: initial;
}

.max-h-25 {
    max-height: 25.5rem;
}

.max-h-240px {
    height: 15rem;
}

// width
.w-1px {
    width: 0.0625rem;
}

.w-16px {
    width: 1rem;
}

.w-20px {
    width: 1.25rem;
}

.w-35px {
    width: 2.1875rem;
}

.w-35 {
    width: 35%;
}

.w-45 {
    width: 45%;
}

.w-40px {
    width: 3rem;
}

.w-50px {
    width: 3.125rem;
}

.w-52px {
    width: 3.25rem;
}

.w-92px {
    width: 5rem;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.w-95 {
    width: 95%;
}

.w-72px {
    width: 4.5rem;
}

.w-38vw {
    width: 38vw;
}

.w-39vw {
    width: 39vw;
}

.w-60 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}
.w-42vw {
    width: 42vw;
}

.w-80vw {
    width: 80vw;
}

.w-85vw {
    width: 85vw;
}

.w-85 {
    width: 85%!important;
}

.w-90vw {
    width: 90vw;
}

.w-unset {
    width: unset !important;
}

.w-fit-content {
    width: fit-content;
}

.w-max-content {
    width: max-content;
}

// min height
.min-height-144px {
    min-height: 9rem;
}

.min-height-142px {
    min-height: 8.875rem;
}

.min-height-40vh {
    min-height: 40vh;
}

.min-height-50vh {
    min-height: 50vh;
}

.min-height-60vh {
    min-height: 60vh;
}

.min-height-80vh {
    min-height: 80vh;
}

.min-height-100vh {
    min-height: 100vh;
}

.min-height-300px {
    min-height: 18.75rem;
}

.min-height-400px {
    min-height: 25rem;
}

.capitalized-text {
    &::first-letter {
        text-transform: uppercase;
    }
}

// translate
.translate-y-15 {
    transform: translateY(-15px);
}

div[data-disabled="true"] {
    opacity: 0.5;
    pointer-events: none !important;
}

.pointer-events-none {
    pointer-events: none;
}

/*--- Text style declaration ---*/
%subText10 {
    @extend %font10;
    color: $black;
    letter-spacing: 0.02988rem;
    font-weight: 500;
}

%subText12 {
    @extend %font12;
    color: $black;
    letter-spacing: 0.02988rem;
    font-weight: 400;
}

%subText14 {
    @extend %font14;
    color: $black;
    letter-spacing: 0.02988rem;
    font-weight: 500;
}

%subText16 {
    @extend %font16;
    color: $black;
    letter-spacing: 0.02988rem;
    font-weight: 400;
}

%p14 {
    @extend %font14;
    color: $black;
    line-height: 1.375rem;
    font-weight: 400;
}

%p16 {
    @extend %font16;
    color: $black;
    line-height: 1.375rem;
    letter-spacing: 0.01992rem;
    font-weight: 400;
}

%p24 {
    @extend %font24;
    color: $black;
    line-height: 1.875rem;
    font-weight: 400;
}

%h6 {
    @extend %font16;
    color: $black;
    line-height: 1.375rem;
    letter-spacing: 0.01992rem;
    font-weight: 700;
}

%h5 {
    @extend %font18;
    color: $black;
    line-height: 1.438rem;
    letter-spacing: 0.01992rem;
    font-weight: 700;
}

%h4 {
    @extend %font24;
    color: $black;
    line-height: 1.875rem;
    font-weight: 600;
}

%h3 {
    @extend %font28;
    color: $black;
    line-height: 2.188rem;
    font-weight: 500;
}

%h2 {
    @extend %font36;
    color: $black;
    line-height: 2.813rem;
    font-weight: 700;
}

%h1 {
    @extend %font56;
    color: $black;
    line-height: 4.438rem;
    font-weight: 500;
}

/* text property */
.text-normal {
    text-transform: unset !important;
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-decoration-none {
    text-decoration: none;
}

.word-wrap {
    word-wrap: break-word;
}

.text-transform-none {
    text-transform: none;
}

.text-transform-capitalize {
    text-transform: capitalize;
}

/* lineheight */
.line-height-0px {
    line-height: 0rem;
}

.line-height-8px {
    line-height: 0.5rem;
}

.line-height-10px {
    line-height: 0.625rem;
}

.line-height-12px {
    line-height: 0.75rem;
}

.line-height-14px {
    line-height: 0.875rem;
}

.line-height-16px {
    line-height: 1rem;
}

.line-height-18px {
    line-height: 1.125rem;
}

.line-height-20px {
    line-height: 1.25rem;
}

.line-height-22px {
    line-height: 1.375rem;
}

.line-height-24px {
    line-height: 1.5rem;
}

.line-height-26px {
    line-height: 1.625rem;
}

.line-height-28px {
    line-height: 1.75rem;
}

.line-height-30px {
    line-height: 1.875rem;
}

.line-height-32px {
    line-height: 2rem;
}

.line-height-34px {
    line-height: 2.125rem;
}

.line-height-36px {
    line-height: 2.25rem;
}

.line-height-38px {
    line-height: 2.375rem;
}

.line-height-40px {
    line-height: 2.5rem;
}

.line-height-42px {
    line-height: 2.625rem;
}

.line-height-44px {
    line-height: 2.75rem;
}

.line-height-46px {
    line-height: 2.875rem;
}

.line-height-48px {
    line-height: 3rem;
}

.line-height-50px {
    line-height: 3.125rem;
}

.line-height-56px {
    line-height: 3.5rem;
}

.line-height-60px {
    line-height: 3.75rem;
}

.line-height-62px {
    line-height: 3.875rem;
}

.line-height-70px {
    line-height: 4.375rem;
}

.line-height-72px {
    line-height: 4.5rem;
}

.line-height-160 {
    line-height: 160%;
}

.letter-spacing-point28 {
    letter-spacing: 0.018rem;
}

/* border radius */
.no-border {
    border: 0;
}

.border-radius-1px {
    border-radius: 0.063rem;
}

.border-radius-2px {
    border-radius: 0.125rem;
}

.border-radius-4px {
    border-radius: 0.25rem;
}

.border-radius-6px {
    border-radius: 0.375rem;
}

.border-radius-8px {
    border-radius: 0.5rem;
}

.border-radius-10px {
    border-radius: 0.625rem;
}

.border-radius-12px {
    border-radius: 0.75rem !important;
}

.border-radius-16px {
    border-radius: 1rem;
}

.border-radius-20px {
    border-radius: 1.25rem;
}

.border-radius-24px {
    border-radius: 1.5rem;
}

.border-radius-28px {
    border-radius: 1.75rem;
}

.border-radius-30px {
    border-radius: 1.875rem;
}

.border-radius-32px {
    border-radius: 2rem;
}

.border-radius-54px {
    border-radius: 3.375rem;
}

.border-radius-100 {
    border-radius: 100%;
}

// border-radius-tr-br
.border-tbr-radius-8px {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.border-tbr-radius-12px {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

// --- padding-all ---
.padding-6px-all {
    padding: 0.375rem;
}

.padding-12px-all {
    padding: 0.75rem;
}

.padding-12px {
    padding: 0.75rem !important;
}

.padding-14px-all {
    padding: 0.875rem;
}

.padding-16px-all {
    padding: 1rem;
}

.padding-18px-all {
    padding: 1.125rem;
}

.padding-24px-all {
    padding: 1.5rem;
}

.padding-26px-all {
    padding: 1.625rem;
}

.padding-32px-all {
    padding: 2rem;
}

.padding-40px-all {
    padding: 2.5rem;
}

// padding-tb
.padding-0-tb {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.padding-4px-tb {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.padding-5px-tb {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
}

.padding-6px-tb {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.padding-6-5px-tb {
    padding-top: 0.40625rem;
    padding-bottom: 0.40625rem;
  }

.padding-8px-tb {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.padding-10px-tb {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.padding-12px-tb {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.padding-14px-tb {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}

.padding-16px-tb {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.padding-18px-tb {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
}

.padding-22px-tb {
    padding-top: 1.375rem;
    padding-top: 1.375rem;
}

.padding-24px-tb {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.padding-32px-tb {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.padding-34px-tb {
    padding-top: 2.125rem;
    padding-bottom: 2.125rem;
}

.padding-36px-tb {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
}

.padding-40px-tb {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.padding-42px-tb {
    padding-top: 2.625rem;
    padding-bottom: 2.625rem;
}

.padding-50px-tb {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
}

// padding-lr
.padding-4px-lr {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.padding-6px-lr {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}

.padding-10px-lr {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

.padding-12px-lr {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.padding-13px-lr {
    padding-left: 0.8125rem;
    padding-right: 0.8125rem;
}

.padding-14px-lr {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
}

.padding-16px-lr {
    padding-left: 1rem;
    padding-right: 1rem;
}

.padding-18px-lr {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
}

.padding-20px-lr {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.padding-22px-lr {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
}

.padding-24px-lr {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.padding-26px-lr {
    padding-left: 1.625rem;
    padding-right: 1.625rem;
}

.padding-28px-lr {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}

.padding-32px-lr {
    padding-left: 2rem;
    padding-right: 2rem;
}

.padding-36px-lr {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
}

.padding-40px-lr {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.padding-50px-lr {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
}

.padding-56px-lr {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}

.padding-60px-lr {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
}

.padding-64px-lr {
    padding-left: 4rem;
    padding-right: 4rem;
}

.padding-70px-lr {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
}

.padding-72px-lr {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
}

.padding-128px-lr {
    padding-right: 8rem;
    padding-left: 8rem;
}

.padding-140px-lr {
    padding-left: 8.75rem;
    padding-right: 8.75rem;
}

.padding-150px-lr {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
}

.padding-160px-lr {
    padding-left: 10rem;
    padding-right: 10rem;
}

// padding-left

.padding-0-left {
    padding-left: 0;
}

.padding-12px-left {
    padding-left: 0.75rem;
}

.padding-26px-left {
    padding-left: 1.625rem !important;
}

.padding-24px-left {
    padding-left: 1.5rem;
}

.padding-28px-left {
    padding-left: 1.75rem !important;
}

.padding-30px-left {
    padding-left: 1.875rem;
}

.padding-30px-left-lg {
    padding-left: 1.875rem;
}

.padding-32px-left {
    padding-left: 2rem;
}

.padding-36px-left {
    padding-left: 2.25rem;
}

.padding-40px-left {
    padding-left: 2.5rem;
}

.padding-50px-left {
    padding-left: 3.125rem;
}

.padding-56px-left {
    padding-left: 3.5rem;
}

.padding-120px-left {
    padding-left: 7.5rem;
}

// padding-right
.padding-8px-right {
    padding-right: 0.5rem;
}

.padding-10px-right {
    padding-right: 0.625rem;
}

.padding-16px-right {
    padding-right: 1rem;
}

.padding-22px-right {
    padding-right: 1.375rem;
}

.padding-24px-right {
    padding-right: 1.5rem;
}

.padding-30px-right {
    padding-right: 1.875rem;
}

.padding-32px-right {
    padding-right: 2rem;
}

.padding-36px-right {
    padding-right: 2.25rem;
}

.padding-40px-right {
    padding-right: 2.5rem !important;
}

.padding-42px-right {
    padding-right: 2.625rem !important;
}


.padding-72px-right {
    padding-right: 4.5rem;
}

.padding-128px-right {
    padding-right: 8rem;
}

// border
.border-all {
    border: 1px solid;
}

.border-all-dashed {
    border: 1px dashed;
}

// border-style

.border-solid {
    border-style: solid;
}

// border-right
.border-right-1px {
    border-right: 1px solid $bright-gray;
}

.border-lg-right-1px {
    border-right: 1px solid $bright-gray;
}

.border-right-1px-lavender-blue {
    border-right: 1px solid $lavender-blue;
}

.border-right-1px-light-silver {
    border-right: 1px solid $light-silver;
}

.border-right-2px {
    border-right: 2px solid $lavender-web;
}

// border-left

.border-left-1px-lights-silver {
    border-left: 1px solid $light-silver;
}

.border-left-2px {
    border-left: 2px solid $pearl-aqua;
}

// border-width
.border-width-2px {
    border-width: 2px !important;
}

// border-top
.border-top-1px {
    border-top: 1px solid;
}

// border-bottom
.border-bottom-1px {
    border-bottom: 1px solid;
}

.border-bottom-2px {
    border-bottom: 2px solid;
}

/* border color */
.border-primary-color {
    border-color: $primary-color;
}

.border-platinum {
    border-color: $platinum;
}

.border-bright-gray {
    border-color: $bright-gray;
}

.border-anti-flash-white {
    border-color: $anti-flash-white;
}

.border-chinese-silver {
    border-color: $chinese-silver;
}

.border-lavender-web {
    border-color: $lavender-web;
}

.border-azureish-white {
    border-color: $azureish-white;
}

.border-light-silver {
    border-color: $light-silver;
}

.border-may-green {
    border-color: $may-green;
}

.border-gray {
    border-color: $gray;
}

.border-transparent {
    border-color: transparent;
}

.border-sea-green {
    border-color: $sea-green;
}

.border-gainsboro {
    border-color: $gainsboro;
}

.border-nyanza {
    border-color: $nyanza;
}

.border-cream-green {
    border-color: $cream_green;
}

.border-true-blue {
    border-color: $true-blue;
}

.border-columbia-blue {
    border-color: $columbia-blue;
}

.border-pearl-aqua {
    border-color: $pearl-aqua;
}

.border-dark-spring-green {
    border-color: $dark-spring-green;
}

.border-azure-blue {
    border-color: $azure-blue;
}

.border-red {
    border-color: $red;
}

.border-lavender-blue {
    border-color: $lavender-blue;
}

.border-polished-pine {
    border-color: $polished-pine;
}

.border-gap {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%231177BD' stroke-width='1' stroke-dasharray='6%2c 8'  stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

// gap
.gap-6px {
    gap: 0.375rem;
}

.gap-8px {
    gap: 0.5rem;
}

.gap-10px {
    gap: 0.625rem;
}

.gap-12px {
    gap: 0.75rem;
}

.gap-14px {
    gap: 0.875rem;
}

.gap-16px {
    gap: 1rem;
}

.gap-18px {
    gap: 1.125rem;
}

.gap-20px {
    gap: 1.25rem;
}

.gap-22px {
    gap: 1.375rem;
}

.gap-24px {
    gap: 1.5rem;
}

.gap-28px {
    gap: 1.75rem;
}

.gap-32px {
    gap: 2rem;
}

.gap-34px {
    gap: 2.125rem;
}

.gap-36px {
    gap: 2.25rem;
}

.gap-40px {
    gap: 2.5rem;
}

.gap-100px {
    gap: 6.25rem;
}

// padding-bottom
.padding-12px-bottom {
    padding-bottom: 0.75rem;
}

.padding-14px-bottom {
    padding-bottom: 0.875rem;
}

.padding-18px-bottom {
    padding-bottom: 1.125rem;
}

.padding-22px-bottom {
    padding-bottom: 1.375rem;
}

.padding-24px-bottom {
    padding-bottom: 1.5rem;
}

.padding-26px-bottom {
    padding-bottom: 1.625rem;
}

.padding-28px-bottom {
    padding-bottom: 1.75rem;
}

.padding-32px-bottom {
    padding-bottom: 2rem;
}

.padding-40px-bottom {
    padding-bottom: 2.5rem;
}

.padding-44px-bottom {
    padding-bottom: 2.75rem;
}

.padding-52px-bottom {
    padding-bottom: 3.25rem;
}

.padding-54px-bottom {
    padding-bottom: 3.375rem;
}

.padding-56px-bottom {
    padding-bottom: 3.5rem;
}

.padding-94px-bottom {
    padding-bottom: 5.875rem;
}

.padding-200px-bottom {
    padding-bottom: 12.5rem;
}

// padding-top
.padding-7px-top {
    padding-top: 0.438rem;
}

.padding-12px-top {
    padding-top: 0.75rem;
}

.padding-14px-top {
    padding-top: 0.875rem;
}

.padding-20px-top {
    padding-top: 1.25rem;
}

.padding-24px-top {
    padding-top: 1.5rem;
}

.padding-28px-top {
    padding-top: 1.75rem;
}

.padding-30px-top {
    padding-top: 1.875rem;
}

.padding-32px-top {
    padding-top: 2rem;
}

.padding-36px-top {
    padding-top: 2.25rem;
}

.padding-42px-top {
    padding-top: 2.625rem;
}

.padding-50px-top {
    padding-top: 3.125rem;
}

.padding-60px-top {
    padding-top: 3.75rem;
}

.padding-64px-top {
    padding-top: 4rem;
}

.padding-74px-top {
    padding-top: 4.625rem;
}

.padding-100px-top {
    padding-top: 6.25rem;
}

.padding-120px-top {
    padding-top: 7.5rem;
}

.padding-160px-top {
    padding-top: 10rem;
}

.padding-180px-top {
    padding-top: 11.25rem;
}

// margin-auto
.margin-auto {
    margin: auto;
}

// margin-top
.margin-9px-top {
    margin-top: 0.5625rem;
}

.margin-12px-top {
    margin-top: 0.75rem;
}

.margin-14px-top {
    margin-top: 0.875rem;
}

.margin-14px-md-top {
    margin-top: 0.875rem;
}

.margin-16px-top {
    margin-top: 1rem;
}

.margin-18px-top {
    margin-top: 1.125rem;
}

.margin-22px-top {
    margin-top: 1.375rem;
}

.margin-24px-top {
    margin-top: 1.5rem;
}

.margin-26px-top {
    margin-top: 1.625rem;
}

.margin-30px-top {
    margin-top: 1.875rem;
}

.margin-32px-top {
    margin-top: 2rem;
}

.margin-34px-top {
    margin-top: 2.125rem;
}

.margin-36px-top {
    margin-top: 2.25rem;
}

.margin-40px-top {
    margin-top: 2.5rem;
}

.margin-42px-top {
    margin-top: 2.625rem;
}

.margin-48px-top {
    margin-top: 3rem;
}

.margin-50px-top {
    margin-top: 3.125rem;
}

.margin-56px-top {
    margin-top: 3.5rem;
}

.margin-58px-top {
    margin-top: 3.625rem;
}

.margin-64px-top {
    margin-top: 4rem;
}

.margin-80px-top {
    margin-top: 5rem;
}

.margin-82px-top {
    margin-top: 5rem;
}

.margin-88px-top {
    margin-top: 5.5rem;
}

.margin-120px-top {
    margin-top: 7.5rem;
}

.margin-120px-lg-top {
    margin-top: 7.5rem;
}

.margin-134px-top {
    margin-top: 8.25rem;
}

.margin-top-20 {
    margin-top: -2.25rem;
}

// margin-bottom
.margin-10px-bottom {
    margin-bottom: 0.625rem;
}

.margin-12px-bottom {
    margin-bottom: 0.75rem;
}

.margin-18px-bottom {
    margin-bottom: 1.125rem;
}

.margin-20px-bottom {
    margin-bottom: 1.25rem;
}

.margin-22px-bottom {
    margin-bottom: 1.375rem;
}

.margin-24px-bottom {
    margin-bottom: 1.5rem;
}

.margin-28px-bottom {
    margin-bottom: 1.75rem;
}

.margin-30px-bottom {
    margin-bottom: 1.875rem;
}

.margin-32px-bottom {
    margin-bottom: 2rem;
}

.margin-36px-bottom {
    margin-bottom: 2.25rem;
}

.margin-40px-bottom {
    margin-bottom: 2.5rem;
}

.margin-42px-bottom  {
    margin-bottom: 2.625rem;
}

.margin-46px-bottom {
    margin-bottom: 2.875rem;
}

.margin-48px-bottom {
    margin-bottom: 3rem;
}

.margin-54px-bottom {
    margin-bottom: 3.375rem;
}

.margin-56px-bottom {
    margin-bottom: 3.5rem;
}

.margin-72px-bottom {
    margin-bottom: 4.5rem;
}

.margin-80px-bottom {
    margin-bottom: 5rem;
}

.margin-118px-bottom {
    margin-bottom: 7.375rem;
}

// margin-left
.margin-6px-left {
    margin-left: 0.375rem;
}

.margin-10px-left {
    margin-left: 0.625rem;
}

.margin-18px-left {
    margin-left: 1.125rem;
}

.margin-36px-left {
    margin-left: 2.25rem;
}

.margin-42px-left {
    margin-left: 2.625rem;
}

.margin-56px-left {
    margin-left: 3.5rem;
}

// margin-right
// Define a Sass mixin with dynamic margin-top values in rem
@mixin margin-right-classes {
    @for $i from 1 through 4 {
        $value: $i * 2; // Calculate multiples of 2px

        .margin-#{$value}px-right {
            margin-right: #{$value * 0.063}rem; // Convert to rem (assuming 1px = 0.063rem)
        }
    }
}

// Use the mixin to generate the classes
@include margin-right-classes;

.margin-14px-right {
    margin-right: 0.875rem;
}

.margin-22px-right {
    margin-right: 1.375rem;
}

.margin-26px-right {
    margin-right: 1.625rem;
}

.margin-32px-right {
    margin-right: 2rem;
}

.margin-34px-right {
    margin-right: 2.125rem;
}

.margin-40px-right {
    margin-right: 2.5rem;
}

.margin-56px-right {
    margin-right: 3.5rem;
}

.margin-64px-right {
    margin-right: 4rem;
}

.margin-68px-right {
    margin-right: 4.25rem;
}

.margin-88px-right {
    margin-right: 5.5rem;
}

.margin-106px-right {
    margin-right: 6.625rem;
}

// Margin block end
.margin-block-end-0 {
    margin-block-end: 0;
}

.margin-24px-lr {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.margin-36px-lr {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
}

// z-index
.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-8 {
    z-index: 8;
}

.z-index-9 {
    z-index: 9;
}

.z-index-10 {
    z-index: 10;
}

.z-index-12 {
    z-index: 12;
}

// cursor
.cursor-pointer {
    cursor: pointer;
}

.cursor-text {
    cursor: text;
}

.cursor-default {
    cursor: default;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

//object-fit
.object-fit-contain {
    object-fit: contain;
}

// outline
.no-outline {
    outline: none;
}

.outline-1px-solid {
    outline: 1px solid $primary-color;
}

// left

.left--20 {
    left: -20px;
}

.left-0 {
    left: 0;
}

.left-10 {
    left: 10px;
}

.left-15px {
    left: 15px !important;
}

.left-18px {
    left: 18px !important;
}

.left-24px {
    left: 1.5rem;
}

.left-45 {
    left: 50px !important;
}

.left-47 {
    left: 47% !important;
}

// top

.top--30 {
    top: -30px;
}

.top--3 {
    top: -3px;
}

.top--4 {
    top: -4px;
}

.top--6 {
    top: -6px;
}

.top--10 {
    top: -10px;
}

.top--14 {
    top: -0.875rem;
}

.top-12px {
    top: 0.75rem;
}

.top-14px {
    top: 0.875rem;
}

.top-8px {
    top: 0.5rem;
}

.top-10px {
    top: 0.625rem;
}

.top-16px {
    top: 1rem;
}

.top-18px {
    top: 1.125rem;
  }

  
.top-20px {
    top: 1.25rem;
}

.top-24px {
    top: 1.5rem;
}

.top-34px {
    top: 2.125rem;
}

.top-40px {
    top: 2.5rem;
}

.top-50px {
    top: 3.125rem;
}

.top-56px {
    top: 3.5rem;
}

.top-66px {
    top: 4.125rem;
}

.top-76px {
    top: 4.5rem;
}

.top-12 {
    top: 12%;
}

.top-13 {
    top: 13%;
}

// bottom
.bottom-0 {
    bottom: 0;
}

.bottom-14px {
    bottom: 0.875rem;
}

.bottom-16px {
    bottom: 1rem;
}

// right
.right-0 {
    right: 0;
}

.right-6px {
    right: 0.375rem;
}

.right-10px {
    right: 0.625rem !important;
}

.right-18px {
    right: 1.125rem;
}

.right-20px {
    right: 1.25rem;
}

.right-22 {
    right: 1.375rem;
}


.right-24 {
    right: 1.5rem;
}

.right-80 {
    right: 5rem;
}

.right--3 {
    right: -3px;
}

.right--592 {
    right: -37rem;
}

// vertical align
.vertical-sub {
    vertical-align: sub !important;
}

// align items
.align-items-flex-start {
    align-items: flex-start;
}

.flex-direction-column {
    flex-direction: column;
}

// truncate
.truncate-first-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-second-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

// white-space
.white-space-nowrap {
    white-space: nowrap;
}

.box-shadow-filter {
    box-shadow: -4px 5px 16px 0px rgba(0, 0, 0, 0.1);
}

.box-shadow-dropdown {
    box-shadow: 4px 4px 48px 0px rgba(7, 0, 124, 0.08);
}

// opacity
.opacity-15 {
    opacity: 0.15;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-4 {
    opacity: 0.4;
}

// rotate gif
.e-rotate-gif {
    transform: rotate(270deg);
    margin-right: 10px;
}

.rotate-90deg {
    transform: rotate(90deg);
}

// overflow

.overflowX-auto {
    overflow-x: auto;
}

.overflowX-hidden {
    overflow-x: hidden;
}

.overflowX-visible {
    overflow-x: visible;
}

.overflowY-auto {
    overflow-y: auto;
}

.overflowY-scroll {
    overflow-y: scroll;
}

.overflowY-hidden {
    overflow-y: hidden;
}

.overflow-wrap-anywhere {
    overflow-wrap: anywhere;
}

.Toastify__toast-body {
    font-family: "Inter-Regular";
    color: $black;
    padding: 6px 12px 6px 6px !important;
}

.Toastify__toast--success .Toastify__toast-icon svg {
    fill: $caribbean-green;
    /* Change the fill color to purple or your desired color */
}

.Toastify__progress-bar--success {
    background: $caribbean-green !important;
}

.e-share-icon {
    transition: 0.3s all ease-in-out;

    &:hover {
        transform: scale(1.03);
        transition: 0.3s all ease-in-out;
    }
}

.animated-underline {
    position: relative;

    &:hover {
        &::after {
            width: 100%;
        }
    }

    &::after {
        content: "";
        position: absolute;
        height: 0.09375rem;
        /* 1.5px converted to rem */
        width: 0;
        border-radius: 0.25rem;
        /* 4px converted to rem */
        background-color: $light-black;
        transition: all 0.5s ease;
        bottom: -0.25rem;
        /* 4px converted to rem */
        left: 0;
    }
}

.e-search-fund-font-14 {
    font-size: 0.875rem !important;
}

.e-arrow-down {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}


.e-report-view-wrapper table {
    font-family: 'Poppins';
    z-index: 1 !important;

    tr {
        top: auto !important
    }
}

.e-report-view-wrapper>table:first-of-type>thead {
    /* display: contents; */
    height: 100px !important;
}

.truncate-fourth-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
}