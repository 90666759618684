@import '../global.scss';

.e_active_btn {
    @extend %font16;

    &:hover {
        background: $ateneo-blue;
        color: $white
    }
}

// inactive btn style
.e_inactive_btn {
    @extend .e_active_btn;
    pointer-events: none;
    opacity: 0.8;
}