@import "../global.scss";

.e_input_wrapper {
    &[data-disabled='true'] {
        pointer-events: none;
        cursor: default;
    }

    .e_input,
    .e_sip_input {
        border: 0.0625rem solid $columbia-blue;
        /* 1px converted to rem */
        border-radius: 0.5rem;
        padding: 0.75rem 3.125rem 0.75rem 1.75rem;
        transition: ease 0.3s;
        box-shadow: 0 0.375rem 0.25rem 0px rgba(50, 50, 71, 0.03);
        /* 4px converted to rem */
    }

    .e_phone_input {
        border: 0.0625rem solid $columbia-blue;
        /* 1px converted to rem */
        border-radius: 0.5rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-right: 3.125rem;
        transition: ease 0.3s;
        box-shadow: 0 0.375rem 0.25rem 0px rgba(50, 50, 71, 0.03);
        /* 4px converted to rem */
    }

    .e_textarea {
        border: 0.0625rem solid $columbia-blue;
        /* 1px converted to rem */
        border-radius: 0.5rem;
        padding: 0.75rem 3.125rem 0.75rem 0.9375rem;
        transition: ease 0.3s;
        box-shadow: 0px 0.375rem 0.25rem 0px rgba(50, 50, 71, 0.03);
        /* 4px converted to rem */
    }

    label {
        top: 50%;
        transform: translateY(-50%);
        transition: ease 0.4s;
        color: $black;
        left: 1.5rem;
    }

    .e_textarea_label {
        top: 12%;
        /* No change, assuming it's relative to container height */
        transform: translateY(-50%);
        transition: ease 0.4s;
        color: $black;
    }

    input label,
    textarea label {
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
    }

    .e_input,
    .e_phone_input,
    // .e_sip_input {

    //     &:focus,
    //     &:valid {
    //         border-color: $primary-color;
    //     }
    // }

    .e_sip_input {

        &:focus~label,
        &:valid~label,
        &[readonly][value]:not([value=""]) {
            border-color: $primary-color;
        }

    }

    .e_input,
    .e_phone_input,
    .e_sip_input {

        &:focus~label,
        &:valid~label,
        &[readonly][value]:not([value=""])~label {
            top: 0;
            padding: 0.2rem !important;
            left: 1.5rem;
            color: $outer-space;

        }
    }

    textarea {

        &:focus~label,
        &:valid~label,
        &[readonly][value]:not([value=""])~label {
            top: 0;
            padding: 0.2rem !important;
            left: 1.5rem;
            color: $black;
        }
    }

    .e_sip_input {

        &:valid~label,
        &[readonly][value]:not([value=""])~label {
            top: 0;
            padding: 0.375rem !important;
            left: 1.5rem;
            color: $primary-color;
        }
    }

    textarea {
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        resize: none;
    }

    .e_phone_number {
        top: 0;
        bottom: 0;
        margin: auto;
        height: fit-content;
        left: 1rem;
    }

    .e_mobile_input {
        &::after {
            content: "";
            width: 1px;
            height: 20px;
            background: $old-silver;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: -6px;
        }
    }

    .e_postLabel {
        top: 50%;
        right: 0;
        transform: translate(-20%, -50%);
    }
}

.e_error_input {

    .e_input,
    .e_textarea,
    .e_phone_input {
        border: 0.0625rem solid red !important;
        /* 1px converted to rem */
    }
}

.e_error_input label {
    color: red !important;
}