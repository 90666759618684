@import "../global.scss";

.e_order_execution {
    .e_dialog {
        max-width: 40.375rem;
    }

    .e_content {
        border-radius: 2rem;
        // height: 95vh;
    }

    .e_close {
        right: 2rem;
        top: 2rem
    }
}

.e_send_mandate_modal {
    .e_dialog {
        max-width: 35.375rem;
    }

    .e_content {
        border-radius: 2rem;
        // height: 95vh;
    }

    .e_close {
        right: 2rem;
        top: 2rem
    }
}