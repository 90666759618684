@import "../global.scss";

.e_fund_modal {
    .e_dialog {
        max-width: 652px;
    }
    .e_content {
        border-radius: 32px;
    }
    .e_placeholder {
        margin: 65px 0 165px 0;
        text-align: center;
        width: 195.001px;
        height: 124px;
    }
    .e_fund_list {
        border-radius: 3px;
        transition: 0.5s all ease-in-out;
        .e_fund_name {
            color: #222b38;
        }
        .e_add_text {
            font-family: "Poppins-Regular";
        }
    }
    .e_fund_list:hover {
        border-radius: 3px;
        background-color: #edf4fd;
        transition: 0.2s all ease-in-out;
        .e_fund_name {
            color: #1177bd;
        }
        .e_add_text {
            font-family: "Poppins-Regular";
            color: #1177bd;
        }
    }
    .e_fund_section {
        height: 340px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-right: 18px;
        &::-webkit-scrollbar {
            width: 0.25rem;
            height: 0;
            display: block !important;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $alice-blue !important;
            border-radius: 0.25rem;
        }
    }
}
