@import "../global.scss";

.e_search_input {
    width: 100%;
    svg {
        right: 12px;
        margin: auto 0;
    }

    input {
        caret-color: black;
        color: #4d4d4d;
        font-size: 16px;
        font-family: "Poppins-Medium";
        border-radius: 16px;
        border-width: 1px;
        border-style: solid;
        border-radius: 8px;
        background: #fff;
        width: 100%;
        padding: 12px 12px 12px;
        height: 100%;
    }
}

.e_search_input input::placeholder {
    color: $outer-space;
    font-size: 14px;
    font-family: "Poppins-Regular";
    line-height: 24px;
}

.e_error_input {
    input {
        border: 0.0625rem solid red !important;
        /* 1px converted to rem */
    }

    input::placeholder {
        color: red;
        font-size: 16px;
        font-family: "Poppins-Regular";
    }
}
