
.e_outline_btn {
    position: relative;
    outline: none;
        &:hover{
            background: #a7c5d77c;
        }
    span{
        z-index: 2;
    }
    

}
.loader_gif {
    width: 20px;
    height: 20px;
    // margin-top: 3px;
    margin-left: 6px;
}