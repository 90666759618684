@import "../global.scss";

.e_sip {
    &::before {
        content: "";
        width: 4px;
        height: 4px;
        background-color: $gray;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -8px;
    }
}
