@media (-webkit-device-pixel-ratio: 1.5) {

    .e-zoom-out-wrapper,
    body {
        zoom: 0.9;
    }

    .e-header {
        width: 100vw;
    }

    .e-sidebar {
        position: fixed!important;
        height: 100%;
        width: 15%!important;
    }

}


@media (-webkit-device-pixel-ratio: 1.75) {

    .e-zoom-out-wrapper,
    body {
        zoom: .8;
    }

    .e-header {
        width: 80%!important;
    }

    .e-sidebar {
        position: fixed!important;
        height: 100%;
        width: 16%!important;
    }
}


// XX-Large devices (larger desktops, 1400px and down)
@media (max-width: 1400px) {
}

@media (max-width: 1350px) {
    .e-payment-other-activities {
        font-size: 0.875rem !important;
    }
}

// X-Large devices (large desktops, 1200px and down)
@media (max-width: 1200px) {
    .e-sidebar-menu-wrapper,
    .e-sidebar-logo {
        zoom: 0.8;
    }

    .example {
        display: none;
    }

    .e-retirement {
        .e-steps {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .e-sector-allocation {
        padding: 0;
    }

    .e-fund-name {
        width: 340px !important;
    }

    .e-payment-other-activities {
        font-size: 0.75rem !important;
    }

    .e-payment-activities-wrapper {
        justify-content: space-between;
        gap: 0 !important;
    }

    .e-onboard-progress {
        width: 800px;
    }
}

@media (max-width: 1024px) {
    html {
        zoom: 1;
    }

    .e-fund-detail-banner-footer {
        .e-nav-column {
            font-size: 1.5rem !important;
        }
    }
}

// Large devices (desktops, 992px and down)
@media (max-width: 992px) {
    .margin-120px-lg-top {
        margin-top: 0;
    }

    .md-w-100 {
        width: 100%;
    }

    .padding-72px-right {
        padding-right: 0.5rem;
    }
    .margin-14px-md-top {
        margin-top: 0.875rem;
    }

    .border-lg-right-1px {
        border: none;
    }

    .margin-120px-lg-top {
        margin-top: 0;
    }

    .margin-14px-md-top {
        margin-top: 0.875rem;
    }

    .padding-30px-left-lg {
        padding-left: 0;
    }

    .e-navigation-hide {
        display: none;
    }

    .e-page-container {
        padding-top: 0.625rem;
    }

    .e-page-container {
        margin-right: 1.75rem;
    }

    .e-payment-other-activities {
        font-size: 0.875rem !important;
    }

    html {
        zoom: 0.9;
    }
}

// Medium devices (tablets, 768px and down)
@media (max-width: 768px) {
    .e-sip-border-none {
        border: none;
    }

    .e-payment-activities-wrapper {
        gap: 24px;
    }
}

// Small devices (landscape phones, 576px and down)
@media (max-width: 576px) {
    html {
        zoom: 0.8;
    }

    .e-quick-invest-scroll {
        &::-webkit-scrollbar {
            height: 0;
            display: initial !important;
        }

        overflow-x: scroll;

        > div:first-of-type {
            width: 31rem;
        }

        .e-quick-invest-card {
            // padding: 1rem 0.75rem;
            padding-top: 1rem;
            padding-bottom: 1rem;

            > img {
                width: 2rem;
                height: 2rem;
            }

            > p {
                font-size: 14px;
            }
        }
    }

    .e-progress-status {
        width: 100%;
    }

    .e-sip-border-none {
        padding-right: 0;
    }

    .e-dashboard {
        .e-dashboard-banner {
            width: 100%;
        }
    }

    .e-featured-mobile {
        padding-left: 16px;
        padding-right: 16px;
    }

    .e-life-card {
        overflow-x: scroll;
        padding-left: 1rem;
        padding-right: 1rem;

        &::-webkit-scrollbar {
            height: 0;
            display: initial !important;
        }

        .e-life-mobile {
            padding: 1rem 1.25rem;

            .e-mobile-margin {
                margin-bottom: 0.5rem;
            }
        }

        > div:first-of-type {
            width: 41.625rem;
        }
    }

    .e-fund-mobile {
        overflow-x: scroll;

        &::-webkit-scrollbar {
            height: 0;
            display: initial !important;
        }
    }

    .e-news-mobile-scroll {
        overflow-x: scroll;

        &::-webkit-scrollbar {
            height: 0;
            display: initial !important;
        }
    }

    .e-sip-mobile {
        border-radius: 0;
    }

    .e-mobile-sip {
        width: 100%;
    }

    .e-page-container {
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .e-risk-assessment {
        .e-risk-range {
            .e-range-slider {
                width: 100%;
            }
        }
    }

    .e-payment-activities-wrapper {
        display: block !important;
    }

    .e-sip-detail-invest-btn {
        width: 100%;
    }

    .padding-40px-right,
    .padding-40px-lr,
    .padding-40px-left {
        padding-right: 1rem !important;
    }
}
