@import "../global.scss";

.e_update_rm {
    .e_dialog {
        max-width: 40.375rem;
    }

    .e_content {
        border-radius: 2rem;
    }
}