@import "../global.scss";

.e_accordion_item {
    border-bottom: 1px solid #eaecf0;
    margin-bottom: 24px;
    /* padding: 24px 24px 24px 16px; */
}

.e_faq_accordion_item {
    margin-bottom: 24px;
    border: 1px solid $black;
    border-radius: 8px;
    /* padding: 24px 24px 24px 16px; */
}

.e_accordion_item h6 {
    align-items: center;
    display: contents;
}

.e_answer {
    margin-top: 28px;
}

.e_accordion_arrow {
    position: absolute;
    right: 24px;
    top: 0;
    right: 0;
}

.e_accordion_item .e_button,
.e_faq_accordion_item_active .e_button {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    text-align: left;
    position: relative;
    line-height: 25.6px;
    padding: 0;
}

.e_accordion_item .e_content {
    line-height: 25.5px;
    padding: 0;
}

.e_accordion_item .e_button[aria-expanded="true"] .e_accordion_arrow,
.e_faq_accordion_item_active .e_button[aria-expanded="true"] .e_accordion_arrow {
    transform: rotate(180deg);
}

.e_faq_accordion_item .e_button,
.e_faq_accordion_item_active .e_button {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    text-align: left;
    position: relative;
    line-height: 25.6px;
    padding: 0;
}

.e_faq_accordion_item_active {
    margin-bottom: 24px;
    border-radius: 8px;
    border: 1px solid $primary-color;
    background: #f5faff;
    padding: 16px;
}

.e_faq_accordion_item .e_content,
.e_faq_accordion_item_active .e_content {
    line-height: 25.5px;
    padding: 0;
}

.e_faq_accordion_item .e_button[aria-expanded="true"] .e_accordion_arrow {
    transform: rotate(180deg);
}

.e_collapse {
    animation: growShrink 0.35s ease-in-out forwards;
}

@keyframes growShrink {
    from {
        height: 100px;
    }

    to {
        height: 0px;
    }
}