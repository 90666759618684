.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #eef2f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

p {
  /* margin: 10px 0; */
  color: #000;
}

h2 {
  /* padding: 20px 0; */
  margin: 0;
  text-align: left;
  color: #000;
}

.add {
  margin-left: 20px;
  padding: 10px 24px;
  background: #1f1136;
  color: #ffffff;
  cursor: pointer;
}

.add:disabled {
  margin-left: 20px;
  padding: 10px 24px;
  background: #1f1136;
  color: #ffffff;
  opacity: 0.8;
  cursor: no-drop;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}