@import '../global.scss';

.e_switch {
    width: 1.875rem; /* 30px converted to rem */
    height: 0.625rem; /* 10px converted to rem */

    .e_head {
        width: 1rem; /* 16px converted to rem */
        height: 1rem; /* 16px converted to rem */
        top: -0.1875rem; /* -3px converted to rem */
        box-shadow: 0px 0.125rem 0.5rem 0px rgba(0, 0, 0, 0.16); /* 2px, 8px converted to rem */
        transition: left 0.3s ease;
    }
}
