@import "../global.scss";

.e_navigation_items {
    border-left: 0.125rem solid transparent;

    &:hover {
        background: $bright-gray;
    }
}

.e_menu_item_wrapper {
    width: max-content;
    height: 85vh;
    padding-bottom: 20px;
    overflow-y: scroll;
}
